<template>
  <div class="home_bottom">
    <div class="bg">
      <div class="div_virtual_wallet">
        <p class="title">¿Cómo funcionan las billeteras virtuales?</p>
        <p class="text2">Experiencia del cliente:</p>
        <div class="div_logo_tag">
          <!-- <div class="div_desc">
            <img src="@/assets/images/img_fouricon1.png" alt="">
            <img src="@/assets/images/img_fouricon2.png" alt="">
            <img src="@/assets/images/img_fouricon3.png" alt="">
            <img src="@/assets/images/img_fouricon4.png" alt="">
          </div> -->
          <div class="div_desc">
            <div class="desc">
              <img src="@/assets/images/img_fouricon1.png" alt="">
              <div>Seleccionar la billetera digital en la página de pago</div>
            </div>
            <div class="desc">
              <img src="@/assets/images/img_fouricon2.png" alt="">
              <div>El código QR se está generando</div>
            </div>
            <div class="desc">
              <img src="@/assets/images/img_fouricon3.png" alt="">
              <div>Ir a la aplicación</div>
            </div>
            <div class="desc">
              <img src="@/assets/images/img_fouricon4.png" alt="">
              <div>El pago se confirma al instante</div>
            </div>
          </div>
        </div>
      </div>
      <ul class="list">
        <li>
          <div>
            <p class="text1">Billeteras Virtuales </p>
            <p class="text2">
              Obtienes acceso a usuarios que prefieren pagar con billeteras virtuales; Más seguridad y conveniencia, todos los datos del cliente se ingresan en la billetera virtual; Se notifica al comerciante tan pronto se complete el pago en el entorno de la billetera virtual; Una integración, acceso a múltiples billeteras: tu sitio web estar listo para acceder a las próximas billetera de tu país
            </p>
          </div>
          <img src="@/assets/images/img_tag_bv.png" alt="">
        </li>
        <li class="li_bg">
          <div>
            <p class="text1">Pagos Locales</p>
            <p class="text2">
              Con las principales tarjetas de crédito y débito, ya que la tecnología se integra directamente, se aceptan los métodos de pago locales más populares a través de una integración
            </p>
          </div>
          <img src="@/assets/images/img_tag_pl.png" alt="">
        </li>
        <li>
          <div>
            <p class="text1">Tarjetas de débito y crédito</p>
            <p class="text2">
              Ofrece los métodos de pago más utilizados y preferidos en Latinoamérica: procesamientos para tarjetas de crédito mediante un enrutamiento inteligente y conexión directa con emisores locales y compradores, tu empresa tendrá tasa de aprobación más altas
            </p>
          </div>
          <img src="@/assets/images/img_tag_tddyc.png" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.home_bottom {
  min-width: 1400px;
  width: 100%;
  .bg {
    background: #FFFFFF;
  }
  .div_virtual_wallet {
    min-width: 1400px;
    background: #3D2158;
    align-items: center;
    text-align: center;
    color: white;
    padding: 60px 180px 80px;
    .title {
      text-align: center;
      font-size: 34px;
      font-weight: 700;
      line-height: 40px;
    }
    .text2 {
      margin-top: 30px;
      font-size: 18px;
      line-height: 25px;
    }
    .div_logo_tag {
      flex: 1;
      margin: 50px auto 0;
      align-items: center;
      text-align: center;
      img {
        width: 66px;
        // max-width: 1000px;
        margin-bottom: 20px;
      }
      .div_desc {
        margin: 0 auto 0;
        min-width: 960px;
        display: flex;
        justify-content: space-between;
        .desc {
          margin-top: 20px;
          width: 200px;
          line-height: 25px;
          font-size: 18px;
        }
      }
    }
  }
  .list {
    overflow: hidden;
    li {
      height: 450px;
      padding: 0 180px 0 180px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-left: 100px;
        height: 219px;
      }
      .text1 {
        min-width: 550px;
        font-size: 34px;
        color: #6940C3;
        font-weight: 700;
        line-height: 40px;
      }
      .text2 {
        margin-top: 26px;
        min-width: 550px;
        font-size: 16px;
        color: #6940C3;
        line-height: 20px;
      }
    }
    .li_bg {
      background: #F5F5F5;
    }
  }
}
</style>
